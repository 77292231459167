<template>
    <div>
        <Header/>
        <div class="grace-body-2">
            <div>
                <button class="grace-button" type="primary" @click="save">保存</button>
            </div>
            <div>
                <div class="grace-form-labels">封面</div>
                <div class="grace-form-body_ss" style="justify-content: space-between; border: 0px;">
                    <center>
                    <div class="grace-add-list-items grace-add-list-btn">
                        <el-upload
                            class="avatar-uploader"
                            action="#"
                            :http-request="httpRequest"
                            :show-file-list="false"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="image" :src="image" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </div>
                    </center>
                </div>
            </div>
            <div class="grace-form-item_s grace-border-b_s" style="border-top: 1px solid #e9e9e9">
                <div class="grace-form-label_s_s">
                    <span>{{type}}</span>
                </div>
                <div>
                   <el-button type="info" :style="{'background-color':button_2?'rgb(102, 102, 102) !important':'#fff','color':button_2?'#fff':'var(--el-button-background-color)'}" class="view" plain @click="button(2)">{{checks}}</el-button>
                   <el-button type="info" :style="{'background-color':button_3?'rgb(102, 102, 102) !important':'#fff','color':button_3?'#fff':'var(--el-button-background-color)'}" plain @click="button(3)">{{image_note}}</el-button>
                   <br />
                   <el-button type="info" :style="{'background-color':button_4?'rgb(102, 102, 102) !important':'#fff','color':button_4?'#fff':'var(--el-button-background-color)'}"  plain @click="button(4)">{{list_and_location}}</el-button>
                   <el-button type="info" disabled class="buttons"></el-button>
                </div>
            </div>

            <div class="grace-form-item_s grace-border-b_s">
                <div class="grace-form-label_s_s">
                    <span>{{titles}}</span>
                </div>
                    <div class="grace-form-body_s">
                    <input type="text" v-model="title" class="grace-form-input_two" :placeholder="title_placeholder">
                </div>
            </div>
            <div class="grace-form-item_s grace-border-b_s">
                <div class="grace-form-label_s_s">
                    <span>{{subheading}}</span>
                </div>
                    <div class="grace-form-body_s">
                    <input type="text" v-model="sub_title" class="grace-form-input_two" :placeholder="text_subheading">
                </div>
            </div>
            <div class="grace-form-item_s grace-border-b_s" v-if="number_status">
                <div class="grace-form-label_s_s">
                    <span>{{set_number}}</span>
                </div>
                    <div class="grace-form-body_s">
                    <input type="number" v-model="visits_number" class="grace-form-input_two" :placeholder="text_number">
                </div>
            </div>
            
            
            <div style="margin-top:60px;">
                <button class="grace-button" type="primary" @click="save">保存</button>
            </div>
            <div style="width:100%;height:20vh;"></div>
        </div>
    </div>
</template>

<script>
import Header from "../Header";
import {apiAddClocks,apiDetailClocks,apiUpdateClocks,apiImageUpload} from "@/request/api";
export default {
  components:{Header},
  data(){
    return {
        // 類型為4：名單及位置記 則顯示拜訪數量給予填寫
        number_status:false,
        title:'',
        sub_title:'',
        // 拜访数量
        visits_number:0,
        // 類型id
        clock_type_id:0,
        button_2:false,
        button_3:false,
        button_4:false,
        image:'',
        user_id:'',
        lang_id:'',

        titles:'',
        title_placeholder:'',
        subheading:'',
        text_subheading:'',
        type:'',
        checks:'',
        image_note:'',
        list_and_location:'',
        set_number:'',
        text_number:''
    }
  },
  created(){
    this.user_id = this.$store.state.user_id;
    let lang_id = this.$store.state.lang_id;
    this.lang_id = lang_id;

    if(lang_id == 1){
      this.titles = this.$zh_ft('title');
      this.title_placeholder = this.$zh_ft('title_placeholder');
      this.subheading = this.$zh_ft('subheading');
      this.type = this.$zh_ft('type');

      this.checks = this.$zh_ft('checks');
      this.image_note = this.$zh_ft('image_note');
      this.list_and_location = this.$zh_ft('list_and_location');
      this.set_number = this.$zh_ft('set_number');
      this.text_number = this.$zh_ft('text_number');
      this.text_subheading = this.$zh_ft('text_subheading');

    }else{
      this.titles = this.$zh_jt('title');
      this.title_placeholder = this.$zh_jt('title_placeholder');
      this.subheading = this.$zh_jt('subheading');
      this.type = this.$zh_jt('type');

      this.checks = this.$zh_jt('checks');
      this.image_note = this.$zh_jt('image_note');
      this.list_and_location = this.$zh_jt('list_and_location');
      this.set_number = this.$zh_jt('set_number');
      this.text_number = this.$zh_jt('text_number');
      this.text_subheading = this.$zh_jt('text_subheading');
    }

      let id = this.$route.params.id;
      if(id && id>0){
          apiDetailClocks(id,{
              user_id:this.user_id,
              lang_id:this.lang_id,
          }).then(res=>{
              if(res.code == 200){
                  this.clock_type_id = res.data.clock_type_id;
                  if(res.data.clock_type_id == 4){
                      this.number_status = true;
                      this.button_4 = true;
                  }else if(res.data.clock_type_id == 3){
                      this.button_3 = true;
                  }else if(res.data.clock_type_id == 2){
                      this.button_2 = true;
                  }
                  this.title = res.data.title;
                  this.visits_number = res.data.visits_number;
                  this.sub_title = res.data.sub_title;
                  this.image = res.data.image;
              }
          }).catch(error=>{
              console.log(error);
          })
      }
  },
  methods:{
      beforeAvatarUpload(file) {
        let types = ['image/png', 'image/jpeg', 'image/jpg'];
        let isUploadFile = types.includes(file['type']);
        let lang_id = this.lang_id;

        // const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isUploadFile) {
            if(lang_id == 1){
                this.$message.error('上傳頭像圖片只能是Image格式!');
            }else{
                this.$message.error('上传头像图片只能是Image格式!');
            }
          return false;
        }
        // if (!isLt2M) {
        //     if(lang_id == 1){
        //         this.$message.error('上傳頭像圖片大小不能超過 2MB!');
        //     }else{
        //         this.$message.error('上传头像图片大小不能超过 2MB!');
        //     }
        //   return false;
        // }
        

        let formData = new FormData();
        formData.append("file", file);
        this.formData = formData;
    
        // return isUploadFile && isLt2M;
        return isUploadFile;
      },
    httpRequest() {
        apiImageUpload(this.formData).then(res=>{
            if(res.code == 200){
                this.image = res.data.file_url;
            }
        })
    },
    save(){
        let id = this.$route.params.id;
        let title = this.title;
        let clock_type_id = this.clock_type_id;
        let lang_id = this.lang_id;
        if(!title){
            if(lang_id == 1){
                this.$message.error('請填寫標題');
            }else{
                this.$message.error('请填写标题');
            }
            return false;
        }
        if(!clock_type_id){
            if(lang_id == 1){
                this.$message.error('請選擇類型');
            }else{
                this.$message.error('请选择类型');
            }
            return false;
        }
        if(!this.image){
            if(lang_id == 1){
                this.$message.error('請選擇圖片');
            }else{
                this.$message.error('请选择图片');
            }
            return false;
        }

        let data = {
            clock_type_id:clock_type_id,
            title:this.title,
            image:this.image,
            sub_title:this.sub_title,
            visits_number:this.visits_number,
            // user_id:this.$store.state.user_id
        }

        if(id && id>0){
            // update時
            apiUpdateClocks(id,data).then(res=>{
                if(res.code == 200){
                    this.$message.success('修改成功');
                    this.$router.push({path:'/task'});
                }else{
                    this.$message.error(res.message);
                    return false;
                }
            }).catch(error=>{
                console.log(error);
            })
        }else{
            // create時
            apiAddClocks(data).then(res=>{
                if(res.code == 200){
                    this.$message.success('添加成功');
                    this.$router.push({path:'/task'});
                }else{
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                console.log(error);
            })
        }
    },
    button(val){
       if(val == 4){
        this.number_status = true;
       }else{
        this.number_status = false;
       }
       
       if(val == 2){
           this.button_2 = true;
           this.button_3 = false;
           this.button_4 = false;
           this.visits_number = 0;
       }else if(val == 3){
           this.button_3 = true;
           this.button_2 = false;
           this.button_4 = false;
            this.visits_number = 0;
       }else if(val == 4){
           this.button_4 = true;
           this.button_3 = false;
           this.button_2 = false;
       }
       this.clock_type_id = val;
    }
  }
}
</script>
<style>
.view:hover {
    background-color: #3f9eff;
    color: #fff;
    border-color: #3f9eff;
  }
  .view {
    color: #409eff;
    background: #ecf5ff;
    border-color: #b3d8ff;
  }
.el-button--info.is-plain:hover, .el-button--info.is-plain:focus{
    background: rgb(102, 102, 102) !important;
}
.buttons{
    border:none;
}
.el-button--info.is-disabled, .el-button--info.is-disabled:hover, .el-button--info.is-disabled:focus, .el-button--info.is-disabled:active{
    background: #fff;
}
.avatar-uploaders{
    width:100%;
    height:100%;
}
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: auto;
    text-align: center;
  }
  .avatar {
    width: 111px;
    height: 111px;
    display: block;
  }
.el-button{
    width:109px;
    font-size: 12px;
    border-radius: 4px;
    color: #000000;
}
.el-button span{
    /* 將span溢出來的内容進行換行 */
    white-space: normal;
}
.el-button--info.is-plain{
    background: #fff;
}
.grace-add-list-btn-text{
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #999;
    width: 100%;  
}
.grace-add-list-btn-icon{
    font-size: 38px;
    height: 38px;
    line-height: 38px;
    margin-bottom: 9px;
    color: #999;
}
.grace-add-list-btn{
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}
.grace-add-list-items{
    width: 106px;
    height: 106px;
    background: #f6f7f8;
    border-radius: 4px;
}
.grace-form-body_ss{
    padding: 30px 0!important;
    width: 90%;
    margin-left: 9px;
    overflow: hidden;
}
.grace-form-labels{
    padding: 30px 0!important;
    width: 10%;
    color: #000000;
    height: 106px;
    font-size: 13px;
    line-height:106px;
    float:left;
}
.grace-button{
    border:none;
    width:100%;
    font-size: 14px;
    line-height: 42px;
    padding: 0;
    border-radius: 2px;
    color: #fff;
    background: #C49B6C !important;
}
::-webkit-input-placeholder{
    color:grey;
    font-size:16px;
}
.grace-form-input_two{
    width: 100%;
    height: 20px;
    outline: none;
    line-height: 20px;
    margin: 10px 0;
    background: none;
    border: 0;
    text-align: right;
    color: #333;
    font-size: 13px;
    text-align: left;
}
.grace-form-body_s{
    width: 350px;
    margin-left: 10px;
    overflow: hidden;
}
.grace-form-label_s_s{
    text-align: left;
    width: 84px;
    display: block;
    width: 90px;
    height: 50px;
    font-size: 14px;
    line-height: 50px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    overflow: hidden;
    color: #000000;
}
.grace-border-b_s{
    border-bottom: 1px solid #e9e9e9;
}
.grace-border{
    border-top: 1px solid #e9e9e9;
}
.grace-form-item_s{
    padding: 5px 0;
    align-items: center;
    display: -webkit-flex;
}
.grace-form{
    margin-top: 5vh;
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
}
.grace-body-2{
    margin-top: -1vh;
    padding: 20px;
    width: unset;
    height: auto;
    background: #fff;
    min-height: 90vh;
}
  .skip{
    position: fixed;
    right: 30px;
    bottom: 20px;
    font-size: 14px;
  }
  
</style>